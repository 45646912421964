.address__card-desc {
    word-break: break-all;
    overflow-wrap: break-word;
    max-width: calc(100% - 54px);
}

.address__card svg {
    z-index: 10;
}


.select__react div div div {
    grid-template-columns: 1fr;
}

.select__react input {
    grid-area: 1 !important;
}

.lyout {
    border-radius: 12px;
    /* background: #fff; */
    background: linear-gradient(180deg, rgba(245, 240, 255, 0.3) 0%, #ECF5FF 100%);
    box-shadow: 0px 0px 30px 0px rgba(33, 96, 154, 0.13);
}
.render-component {
            text-align: center;
}

@media (max-width:720px) {
    .lyout {
        border-radius: 0 !important;
        min-height: 100vh;
    }

    .lyout .rgt {
        padding-bottom: 10px !important;
    }

    .address {
        height: auto !important;
        min-height: 340px;
    }
}

.lyout .lft {
    background: linear-gradient(180deg, #fff -13.17%, #e0eeff 100%);
    flex-basis: 45.31%;
    padding: 68px 24px 68px;
}

.lyout .lft img {
    max-width: 460px;
}

.lyout .bre__title {
    padding: 10px 32px;
}

.lyout .rgt {
    padding: 24px 0;
    min-height: 500px;
}

.lyout .rgt .rgt__inner {
    padding: 10px 32px;

}

.rgt__inner--scroll {
    max-height: 500px;
    overflow-y: scroll;
}

.lyout .rgt .fotr img {
    height: 50px;
}

.lyout h2 {
    color: #3c3c3c;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 780px) {
    .inputs {
        max-width: 100%;
    }
}

.input [class*=control] {
    height: 45px;
}

.input span,
.input input,
.input select {
    color: #696969;
    font-size: 14px;
    font-weight: 500;
}

.input input,
.input select {
    color: #3c3c3c;
    padding: 12px;
}

.btn {
    border-radius: 6px;
    background: #e73f4e !important;
    font-weight: 500;
    font-size: 16px;
}

.term * {
    color: #4f4f50;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
}

.resend {
    color: #ee3338;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.skip {
    border-radius: 4px;
    background: #f3f3f3;
    color: #6a6a71;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 22px;
    max-width: 73px;
    margin-left: auto;
}

.otp-fields input {
    width: 48px;
    height: 48px;
    border: 0.5px solid #3c3c3c;
    border-radius: var(--bs-border-radius);
    margin-right: 15px;
    text-align: center;
}

.otp-fields input:last-child {
    margin-right: 0;
}

.capitalize select {
    text-transform: capitalize;
}

.sumary .desc {
    color: #696969;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;
}

.sumary h3,
.sumary h4 {
    color: #465b97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 175%;
    max-width: 398px;
}

@media (max-width: 420px) {
    .sumary h4 {
        font-size: 14px;
    }
}

.sumary h4 {
    color: #3c3c3c;
}

.sumary h4,
.sumary .lst,
.sumary .acc,
.sumary .fotr {
    max-width: 470px;
}

.sumary .acc * {
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

.sumary .acc h4 {
    padding-right: 10px !important;
}

.sumary .acc button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
}

.sumary .acc .rsult {
    color: #696969;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
}

.sumary .lst {
    color: #696969;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.sumary .lst span:last-child,
.sumary h4 span:last-child {
    color: #ee3338;
}

.sumary h4 .blu {
    color: #465b97 !important;
}

.sumary h4 span:last-child {
    font-weight: 600;
}

.meter {
    width: max-content;
    height: max-content;
}

.meter .arrow {
    width: 61px;
    height: 61px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(180deg);
    transition: all 0.6s linear;
}

.meter .arrow svg {
    top: -67px;
}

.consent h3,
.consent p {
    color: #3d3d3e;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.consent p {
    color: #4f4f50;
    font-weight: 400;
}

.consent .checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #f05256;
}

.reason__keys {
    padding-left: 24px;
    color: #f05256;
}

.question p:first-child,
.question p:last-child,
.question label {
    color: #696969;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.logo__brand {
    max-width: 160px !important;
    top: 20px;
    left: 20px;
}

.question p:last-child,
.question label {
    color: #3c3c3c;
}

.consent {
        /* max-width: 550px; */
}

.form-container {
    display: flex;
    justify-content: center;
}

.form-container label,button {
    max-width: 530px;
}
.income-box {
        display: flex;
            align-items: center;
            flex-direction: column;
            border: none !important;
}

.amount-box {
        margin: 15px 0;
        padding: 10px 15px;
            border: none !important;
            align-items: flex-start;
}
.income-box img {
    width: 70px;
    margin-top: 10px;
}

.income-box .i-type {
    color: #465B97 !important;
    font-size: 17px !important;
    margin-bottom: 15px !important;
    margin-top: 20px;
}
.income-box p {
    margin-bottom: 20px;
}

.income-active {
    border: 1px solid #A0B8FE !important;
}

.income-types {
    grid-template-columns: 1fr 1fr 1fr !important;
}

.consent div {
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 1200px) {
    .lyout .bre__title {
        padding: 10px 20px;
    }

    .lyout .rgt {
        padding: 20px 0;
    }

    .lyout .rgt .rgt__inner {
        padding: 10px 20px;
    }
}

@media (max-width: 1200px) {
    .consent div {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 780px) {
    .lyout .bre__title {
        padding: 10px 16px;
    }

    .lyout .lft {
        display: none !important;
    }

    .lyout_score .lft {
        display: flex !important;
    }

    .lyout .rgt {
        padding: 20px 0;
    }

    .lyout .rgt .rgt__inner {
        padding: 10px 16px;
    }

    .lyout .rgt h2 {
        font-size: 20px;
    }

    .btn {
        padding: 11px 16px !important;
    }

    .consent div,
    .lyout_score {
        flex-direction: column;
    }

    .consent [class*="-control"],
    .consent [class*="-control"] div {
        flex-direction: row;
    }

    .lyout_score .lft {
        padding: 30px 0 40px;
    }

    .otp-fields {
        gap: 0 !important;
    }
}

.score_value {
    color: #465b97;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.score_limit {
    width: 170px;
    margin-top: 4px;
}

.score_limit p {
    color: #3c3c3c;
    font-weight: 500;
}

.address {
    height: 340px;
}

.address__card {
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px rgba(33, 96, 154, 0.1);
    z-index: 100;
}

.address__card-desc {
    font-size: 14px !important;
}

.professions {
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 540px) {
    .professions {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 420) {
    .professions {
        grid-template-columns: 1fr;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
}

.utm-thank__title {
    color: #3c3c3c;
    font-size: 24px;
    margin-bottom: 32px;
}

.utm-thank__desc {
    color: #465b97;
}

.utm-dedupe__desc {
    color: #e73f4e;
}

.utm-thank__btns {
    margin-top: 60px;
}


.utm-thank__btns button:first-child {
    border: 1px solid #e73f4e;
    color: #e73f4e;
    background-color: #fff;
    border-radius: 6px;
    padding: 12px 48px;
}

.utm-thank__btns .re-apply {
    border: 1px solid #465b97 !important;
    color: #fff !important;
    background-color: #465b97 !important;
    border-radius: 6px;
    padding: 12px 48px;
}

.utm-thank__btns button:last-child {
    border: none;
    background: #e73f4e;
    border-radius: 6px;
    padding: 12px 48px;
}

.thank-you__link {
    margin-top: 32px;
    color: #3c3c3c;
    font-weight: 500;
}

.add__btn svg {
    width: 20px;
    height: 20px;
    color: #e73f4e;
}

.add__btn p {
    color: #e73f4e;
    font-weight: 500;
}

.status_pending {
    color: #f90;
}

.profession__card {
    box-shadow: 0px 2px 10px 0px #8398d33D;
    border-radius: 6px;
    min-height: 190px;
    transition: all 0.3s ease-in-out;
}

.profession__card img {
    max-height: 60px;
    object-fit: contain;
}

.profession__card.active,
.profession__card:hover {
    box-shadow: 0px 2px 10px 0px #e73f4d65;
    transform: translateY(-6px);
}

.profession__card h3 {
    font-size: 16px;
    color: #465b97;
    font-weight: 700;
}

.profession__card p {
    font-size: 12px;
}



.profession__card--new {
    font-size: 16px !important;
    font-weight: 500;
}

.profession__card--add {
    background: #ffdadd;
    border-radius: 50%;
    padding: 16px;
}

@media (max-width: 540px) {
    .profession__card {
        max-width: 100%;
    }
}

.profession__card p {
    line-height: 24px;
    color: #5d5f69;
}

@media (max-width: 442px) {
    .utm-thank__title {
        padding-top: 20px;
    }

    .utm-thank__btns button {
        flex-grow: 1;
    }
}

@media (max-width: 360px) {
    .lyout_score .meter>svg {
        width: 160px;
        height: 160px;
    }

    .lyout_score .meter div svg {
        width: 50px;
        height: 120px;
        top: -50px;
        left: 10px;
    }

    .score_limit {
        margin-top: 0;
        width: 120px;
    }
}

@media (max-width: 290px) {
    .otp-fields input {
        padding: 7px;
        width: 36px;
        height: 36px;
    }
}

.input [class*=control] {
    height: 45px;
}

.input span,
.input input,
.input select {
    color: #696969;
    font-size: 14px;
    font-weight: 500;
}

.input input,
.input select {
    color: #3c3c3c;
    padding: 12px;
}


.checkmark__message {
    font-size: 14px !important;
    font-weight: 600 !important;
}