body {
    padding: 0;
}


.inset-0 {
    inset: 0 0 0 0;
}

.wrapper-container {
    height: 100vh;
}

.cursor-pointer {
    cursor: pointer !important;
}

@media (max-width:720px) {
    .wrapper-container>div {
        padding: 0 !important;
    }
}

.modal-backdrop .show {
    opacity: .5;
}

.modal-header {
    justify-content: space-between;
}

.modal-header button {
    border: none;
    background: transparent;
}

.cibil__branch__option {
    text-transform: capitalize;
    font-size: 14px !important;
    padding: 6px 12px !important;
}